import { IBusiness, IBusinessesFilter, IBusinessProfileData } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from './helpers';

interface IBusinessUpdateProfileParams {
  businessId: TID,
  business: IBusinessProfileData,
}

interface IBusinessUpdateProfileResponse {
  business: IBusiness,
}

const getBusiness = (id: number): Promise<IBusiness> => {
  return apiGet(`/api/web/v1/businesses/${id}`)
    .then((data) => camelizeKeys(data.business) as IBusiness);
};

interface IGetBusinessByManagementGroupParams {
  managementGroupId: TID
}

const getBusinessByManagementGroup = (
  params: IGetBusinessByManagementGroupParams,
): Promise<IGetBusinessesResponse> => {
  return apiGet(
    '/api/v1/businesses/by_management_group',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.businesses as IBusiness[],
    };
  });
};

const getBusinessesForPortfolio = (
  params: IGetBusinessesParams,
): Promise<IGetBusinessesResponse> => {
  return apiGet(
    '/api/v1/portfolios/businesses',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.businesses as IBusiness[],
    };
  });
};

const updateBusinessProfile = (
  params: IBusinessUpdateProfileParams,
): Promise<IBusinessUpdateProfileResponse> => {
  return apiPut(
    `/api/web/v1/businesses/${params.businessId}/update_profile`,
    underscoreKeys({ business: params.business }),
  ).then((data) => ({ business: camelizeKeys(data.business) as IBusiness }));
};

interface IGetBusinessesParams {
  forClient?: boolean,
  includeAccessibleBusinesses?: boolean,
  includeSystemBusinesses?: boolean,
  includeEmployedBusiness?: boolean,
  simple?: boolean,
  search?: string,
  filter?: IBusinessesFilter,
  coaId?: TID,
  forDataExport?: boolean | null,
  page?: number,
  perPage?: number,
  managementGroupId?: TID,
}

interface IGetBusinessesResponse {
  meta: {
    totalCount: number,
  },
  collection: IBusiness[],
}

const getBusinesses = (
  params: IGetBusinessesParams,
): Promise<IGetBusinessesResponse> => {
  return apiGet(
    '/api/v1/businesses',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.businesses as IBusiness[],
    };
  });
};

interface IGetLinkedBusinessesParams {
  id: TID,
  vendorId: TID,
}

interface IGetLinkedBusinessesResponse {
  businesses: IBusiness[]
}

const getLinkedBusinesses = (
  params: IGetLinkedBusinessesParams,
): Promise<IGetLinkedBusinessesResponse> => {
  return apiGet(
    '/api/v1/businesses/linked_businesses',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetLinkedBusinessesResponse);
};

interface ICreateBusinessParams {
  business: {
    addBusinessAdvisor: boolean,
    displayName: string,
    name: string,
    standardCategoryId?: TID,
    serviceProviderBusinessId: TID,
  }
}

interface ICreateClientBusinessParams {
  accountingFirmBusinessId: TID;
  qboCustomerId: TID;
}

interface ICreateBusinessResponse {
  business: IBusiness
}

const createBusiness = (
  params: ICreateBusinessParams,
): Promise<ICreateBusinessResponse> => {
  return apiPost(
    '/api/v1/businesses',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateBusinessResponse);
};

/**
 * Calls the API endpoint to import data from ledger for the given business.
 *
 * @param businessId - The ID of the business for which ledger data should be imported.
 * @returns A promise that resolves with the transformed response.
 */

interface IImportDataFromLedgerParams {
  businessId: TID,
}

const importDataFromLedger = (params: IImportDataFromLedgerParams): Promise<any> => {
  return apiPost(
    `/api/v1/businesses/${params.businessId}/import_data_from_ledger`,
    underscoreKeys({}),
  ).then((data) => camelizeKeys(data));
};

export {
  IBusinessUpdateProfileParams,
  IImportDataFromLedgerParams,
  IBusinessUpdateProfileResponse,
  IGetBusinessesParams,
  IGetBusinessesResponse,
  IGetLinkedBusinessesParams,
  IGetLinkedBusinessesResponse,
  ICreateBusinessParams,
  ICreateBusinessResponse,
  IGetBusinessByManagementGroupParams,
  ICreateClientBusinessParams,
  updateBusinessProfile,
  getBusiness,
  getBusinessByManagementGroup,
  getBusinessesForPortfolio,
  getBusinesses,
  getLinkedBusinesses,
  createBusiness,
  importDataFromLedger,
};
