import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IBusinessUpdateProfileParams,
  IBusinessUpdateProfileResponse,
  updateBusinessProfile,
  getBusiness,
  IGetBusinessesParams,
  IGetBusinessesResponse,
  getBusinesses,
  IGetLinkedBusinessesParams,
  IGetLinkedBusinessesResponse,
  getLinkedBusinesses,
  ICreateBusinessParams,
  ICreateBusinessResponse,
  createBusiness,
  getBusinessByManagementGroup,
  getBusinessesForPortfolio,
  IGetBusinessByManagementGroupParams,
  importDataFromLedger,
  IImportDataFromLedgerParams,
} from '@src/requests/businesses';
import { IBusiness } from '@src/types/businesses';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetBusinessQuery = (id: number) => {
  return useQuery<IBusiness, Error>(
    [QueryKey.business, id],
    () => getBusiness(id),
    {
      enabled: !!id,
    },
  );
};

const useUpdateBusinessProfile = () => {
  const queryClient = useQueryClient();

  return useMutation<IBusinessUpdateProfileResponse, Error, IBusinessUpdateProfileParams>(
    updateBusinessProfile,
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries([QueryKey.business, params.businessId]);
      },
    },
  );
};

const useGetBusinesses = createUseGetInfiniteCollection<
  IBusiness,
  IGetBusinessesParams,
  IGetBusinessesResponse
>({
  queryKey: QueryKey.businesses,
  request:  getBusinesses,
});

const useGetBusinessesByManagementGroup = createUseGetInfiniteCollection<
  IBusiness,
  IGetBusinessByManagementGroupParams,
  IGetBusinessesResponse
>({
  queryKey: QueryKey.businesses,
  request:  getBusinessByManagementGroup,
});

const useGetBusinessesForPortfolio = createUseGetInfiniteCollection<
  IBusiness,
  IGetBusinessesParams,
  IGetBusinessesResponse
>({
  queryKey: QueryKey.businesses,
  request:  getBusinessesForPortfolio,
});

const useGetLimitedBusinesses = (params: IGetBusinessesParams) => {
  return useQuery<IGetBusinessesResponse, Error>(
    [QueryKey.businesses, params],
    () => getBusinesses(params),
  );
};

const useGetLinkedBusinesses = (params: IGetLinkedBusinessesParams) => {
  return useQuery<IGetLinkedBusinessesResponse, Error>(
    [QueryKey.linkedBusinesses, params],
    () => getLinkedBusinesses(params),
  );
};

const useCreateBusiness = () => {
  return useMutation<ICreateBusinessResponse, Error & AxiosError, ICreateBusinessParams>(
    createBusiness,
  );
};

const useImportDataFromLedger = () => {
  return useMutation<{}, Error & AxiosError, IImportDataFromLedgerParams>(
    importDataFromLedger,
  );
};

export {
  useGetBusinessQuery,
  useUpdateBusinessProfile,
  useGetBusinesses,
  useGetLimitedBusinesses,
  useGetLinkedBusinesses,
  useCreateBusiness,
  useGetBusinessesByManagementGroup,
  useGetBusinessesForPortfolio,
  useImportDataFromLedger,
};
