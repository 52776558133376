import { GroupChannel } from '@sendbird/chat/groupChannel';
import { InfiniteQueryObserverResult } from 'react-query';
import { atom } from 'recoil';

import { ChatBotAtomKeys } from '@src/components/ai_chatbot/constants/atom_keys';
import {
  IFirstMessageAtom,
  IHistoryItemsRequestState,
  IThreadsRequestState,
  TChatMessageLoaderAtom,
} from '@src/components/ai_chatbot/types';

// Active Channel atom
export const activeChannelAtom = atom<GroupChannel | undefined>({
  key:     ChatBotAtomKeys.ActiveChannel,
  default: undefined,
});

// Active Channel ID atom
export const activeChannelIdAtom = atom<string>({
  key:     ChatBotAtomKeys.ActiveChannelId,
  default: '',
});

// Active Thread ID atom
export const activeThreadIdAtom = atom<string>({
  key:     ChatBotAtomKeys.ActiveThreadId,
  default: '',
});

// channels atom
export const channelsAtom = atom<Record<GroupChannel['url'], GroupChannel>>({
  key:     ChatBotAtomKeys.Channels,
  default: {},
});

// Active Thread Messages atom
export const threadsAtom = atom<IThreadsRequestState>({
  key:     ChatBotAtomKeys.Threads,
  default: {
    data:      {},
    isLoading: false,
    error:     null,
  },
});

// History Items atom
export const historyItemsAtom = atom<IHistoryItemsRequestState>({
  key:     ChatBotAtomKeys.HistoryItems,
  default: {
    data:      [],
    isLoading: false,
    error:     null,
  },
});

// Add this new atom
export const threadLoadingStatesAtom = atom<
  Record<string, InfiniteQueryObserverResult['status']>
>({
  key:     ChatBotAtomKeys.ThreadLoadingStates,
  default: {},
});

// Conversation Loader atom
export const conversationLoaderAtom = atom<boolean>({
  key:     ChatBotAtomKeys.ConversationLoader,
  default: false,
});

// Disable Chat Input atom
export const disableChatInputAtom = atom<boolean>({
  key:     ChatBotAtomKeys.DisableChatInput,
  default: false,
});

/**
 * any question/text that is being sent to the chatbot
 * it's messageSignature will be put in this array
 * once the answer is received,
 * the messageSignature will be removed from the array
 */
export const chatMessageLoaderAtom = atom<TChatMessageLoaderAtom>({
  key:     ChatBotAtomKeys.ChatMessageLoader,
  default: [],
});

export const firstMessageAtom = atom<IFirstMessageAtom>({
  key:     ChatBotAtomKeys.FirstMessage,
  default: { message: '', messageSignature: '' },
});

export const isNavBarVisibleAtom = atom<boolean>({
  key:     ChatBotAtomKeys.IsNavBarVisible,
  default: true,
});
