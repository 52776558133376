import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBusinessesForPortfolio } from '@src/hooks/queries/businesses';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';

import { useCreatePortfolioModal } from '../business_reports/custom_reports/modals/create_portfolio';
import { PlusIconCircle } from '../utils/fa_icons';

import styles from './styles.module.scss';

const AddNewPortfolio = () => {
  const CreatePortfolioModal = useCreatePortfolioModal();
  const isPortfolioFeatureEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION, true);

  const query = useGetBusinessesForPortfolio({
    forDataExport: true,
    perPage:       100,
  });

  const businesses = useMemo(() => {
    const businessesPages = query.data?.pages || [];
    return flatten(businessesPages.map((p) => p.collection));
  }, [query.data?.pages]);
  if (!isPortfolioFeatureEnabled) {
    return null;
  }

  return (
    <>
      <li className={ styles['border-top'] }>
        <a
          className="submenu-title"
          role="button"
          tabIndex={ 0 }
          onClick={ () => CreatePortfolioModal.open() }
        >
          <div className="m-l-18 m-t-8 m-b-8">
            <PlusIconCircle />
            <span className="m-l-5">Add New Portfolio</span>
          </div>
        </a>
      </li>
      <CreatePortfolioModal.Component
        { ...CreatePortfolioModal.props }
        businesses={ businesses }
      />
    </>
  );
};

export default AddNewPortfolio;
