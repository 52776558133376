export const ChatBotAtomKeys = {
  ActiveChannel:       'active_channel',
  ActiveChannelId:     'active_channel_id',
  ActiveThreadId:      'active_thread_id',
  Channels:            'channels',
  Threads:             'threads',
  HistoryItems:        'history_items',
  ThreadLoadingStates: 'thread_loading_states',
  ConversationLoader:  'conversation_loader',
  ChatStatus:          'chat_status',
  DisableChatInput:    'disable_chat_input',
  ChatMessageLoader:   'chat_message_loader',
  FirstMessage:        'first_message',
  IsNavBarVisible:     'is_navbar_visible',
};
