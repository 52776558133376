import React, { useCallback, useMemo, useState } from 'react';

import { flatten } from 'lodash';

import toastr from '@lib/toastr';
import { useGetOnboardingStatusClientBusinesses } from '@src/hooks/queries/onboarding_statuses';
import { useLocalStorage } from '@src/hooks/storage';
import { clientBusinessSettingsPath } from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';
import { redirectToBusinessService, backboneNavigateToUrl } from '@src/utils/navigate';

import { TIconOption } from '@src/components/ui_v2/inputs/types';
import { SearchDropdownInput } from '@src/components/ui_v2/search_dropdown';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import NewPortfolioDropDownList from './new_portfolio_dropdown_option';
import PortfolioDropDownList from './portfolio_dropdown_list';

import styles from './styles.module.scss';

const clientAvatar = (
  clientBusiness: IClientBusiness,
) => {
  if (clientBusiness.imageUrl === null) {
    return <BusinessDefaultAvatarIcon />;
  }

  return (
    <img
      alt="Avatar"
      className={ styles['business-avatar'] }
      src={ clientBusiness.imageUrl }
    />
  );
};

const businessAvatar = (
  business: IBusiness,
) => {
  if (business.avatarUrl === null) {
    return <BusinessDefaultAvatarIcon />;
  }

  return (
    <img
      alt="Avatar"
      className={ styles['business-avatar'] }
      src={ business.avatarUrl }
    />
  );
};

const clientsToOptions = (
  clientBusinesses: IClientBusiness[],
) => {
  return clientBusinesses.map((client) => {
    return {
      label:      client.name,
      value:      String(client.clientBusinessId),
      businessId: String(client.businessId),
      icon:       clientAvatar(client),
    };
  });
};

const businessToOption = (
  business: IBusiness,
) => {
  return {
    label: business.name,
    value: String(business.id),
    icon:  businessAvatar(business),
  };
};

const placeholder: TIconOption = {
  label: 'Select Client',
  icon:  <BusinessDefaultAvatarIcon />,
  value: '',
};

interface TSearchDropdownIconOption extends TIconOption {
  businessId?: string,
}

interface IClientViewProps {
  currentClientBusiness?: IBusiness,
}

const ClientView = ({
  currentClientBusiness,
}: IClientViewProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [resetPortfolioKey, setResetPortfolioKey] = useState<number>(0);
  const { setValue: setSelectedPortfolio } = useLocalStorage('selectedPortfolio', {});

  const query = useGetOnboardingStatusClientBusinesses({
    searchQuery: searchValue,
    perPage:     100,
  });

  const clientBusinesses = useMemo(() => {
    const clientBusinessesPages = query.data?.pages || [];
    return flatten(clientBusinessesPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const clientBusinessesOptions = useMemo(() => {
    if (!clientBusinesses) return [];

    return clientsToOptions(clientBusinesses);
  }, [clientBusinesses]);

  const handleSelect = useCallback((selectedOption: TSearchDropdownIconOption) => {
    if (!selectedOption.businessId || !selectedOption.value) return;

    const newClientBusinessId = selectedOption.value;
    const newClientBusiness = clientBusinesses?.find(
      (clientBusiness) => clientBusiness.clientBusinessId === Number(newClientBusinessId),
    );

    if (newClientBusiness && !newClientBusiness.hasBusinessAccess) {
      toastr.error('You do not have access to this module in this business. Give access to yourself.', 'Error');
      backboneNavigateToUrl(clientBusinessSettingsPath(
        newClientBusiness.clientBusinessId,
        newClientBusiness.businessId,
      ));
      return;
    }

    setSelectedPortfolio({});
    setResetPortfolioKey((prev) => prev + 1);
    redirectToBusinessService(
      selectedOption.businessId,
      newClientBusinessId,
      true,
      currentClientBusiness,
      newClientBusiness,
    );
  }, [clientBusinesses, currentClientBusiness, setSelectedPortfolio, setResetPortfolioKey]);

  const currentOption = useMemo(() => {
    if (!currentClientBusiness) return undefined;

    return businessToOption(currentClientBusiness);
  }, [currentClientBusiness]);

  const [globalNavigationUserEnabled, setGlobalNavigationUserEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (globalNavigationUserEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setGlobalNavigationUserEnabled(res);
    });
  }

  const isGlobalNavigationEnabled: boolean = !!globalNavigationUserEnabled;
  const dropdownKey = `client-dropdown-${resetPortfolioKey}`;

  return (
    <div className={ styles['business-client-items-view'] }>
      <div className="global-horizontal-divider" />
      {isGlobalNavigationEnabled ? (
        <NewPortfolioDropDownList
          key={ dropdownKey }
          launchDarklyFeature={ window.Docyt.Common.Constants.KPI_PORTFOLIO_DASHBOARD }
        />
      ) : (
        <PortfolioDropDownList
          key={ dropdownKey }
          launchDarklyFeature={ window.Docyt.Common.Constants.KPI_PORTFOLIO_DASHBOARD }
        />
      )}
      <SearchDropdownInput
        className={ styles['business-dropdown-input'] }
        options={ clientBusinessesOptions }
        placeholder={ placeholder }
        searchPlaceholder="Search your clients"
        searchValue={ searchValue }
        value={ currentOption || null }
        onSearch={ setSearchValue }
        onSelect={ handleSelect }
      />
    </div>
  );
};

export {
  TSearchDropdownIconOption,
  ClientView as default,
};
