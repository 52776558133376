/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IItemValue } from '@src/types/report_service/report_data';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';

import { searchColumnValueType } from '@src/components/ui_v2/filter/atoms';

import Row from './row';
import { IHeaderColumn } from '../../hooks';
import { useReportOtherDetailItemsBySearchName } from '../../tableHooks';

interface ISecondDrillDownTableBodyProps {
  report: IReport,
  itemAccountValues: IItemAccount[],
  itemValues: IItemValue[],
  multiMonthColumns: IReportColumn[],
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[],
  itemIdentifier: IReportItem,
  headerTopRowColumns: IHeaderColumn[],
  headerBottomRowColumns: IHeaderColumn[],
  items: IItemAccount[],
  currentDepth: number
}

const SecondDrillDownTableBody = (
  {
    report,
    itemAccountValues,
    itemValues,
    multiMonthColumns,
    ptdColumns,
    ytdColumns,
    itemIdentifier,
    headerTopRowColumns,
    headerBottomRowColumns,
    items,
    currentDepth,
  }: ISecondDrillDownTableBodyProps,
) => {
  const searchValue = useRecoilValue(searchColumnValueType);
  useReportOtherDetailItemsBySearchName(items, searchValue);

  return (
    <>
      {items?.map((item: IItemAccount) => (
        <React.Fragment key={ item.id }>
          { item.show && item.search && (
            <Row
              currentDepth={ currentDepth }
              headerBottomRowColumns={ headerBottomRowColumns }
              headerTopRowColumns={ headerTopRowColumns }
              item={ item }
              itemAccountValues={ itemAccountValues }
              itemIdentifier={ itemIdentifier }
              itemValues={ itemValues }
              multiMonthColumns={ multiMonthColumns }
              ptdColumns={ ptdColumns }
              report={ report }
              ytdColumns={ ytdColumns }
            />
          ) }
        </React.Fragment>
      ))}
    </>
  );
};

export default React.memo(SecondDrillDownTableBody);
