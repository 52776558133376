import React, { useMemo } from 'react';

import {
  Chart as ChartJS,
  registerables,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar, Line } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';

import { IBusinessSimple } from '@src/types/businesses';
import { TDateRange } from '@src/types/common';
import { IDataSet } from '@src/types/dashboards/data_sets';
import { TChartType, TViewOption } from '@src/types/dashboards/widgets';
import { startOfMonthDate, formatDate, formatMonth, getUTCTimezone, endOfMonthDate } from '@src/utils/date_helpers';

import {
  TChartDataInfo,
  fillData, getDataSetLabel, getDataSetColor,
} from './chart_helpers';
import {
  briefViewOptions,
  fullViewOptions,
} from './chart_options';
import { toggleTargetsState } from '../../atoms';

ChartJS.register(...registerables, annotationPlugin);

interface IChartViewProps {
  businesses?: IBusinessSimple[],
  chartType?: TChartType,
  isStackedBar?: boolean,
  briefView?: boolean,
  viewOption?: TViewOption,
  scale?: string,
  dataSets: IDataSet[],
  dateRange: TDateRange,
  onMonthlyLaunch?: (event: any, elements: any) => void,
  target?: number,
}

const ChartView = ({
  businesses,
  chartType,
  isStackedBar,
  briefView,
  viewOption,
  scale,
  dataSets,
  dateRange,
  onMonthlyLaunch,
  target,
}: IChartViewProps): JSX.Element | null => {
  const dataInfo = useMemo(() => {
    const startDate = getUTCTimezone(new Date(dateRange.startDate!));
    const endDate = getUTCTimezone(new Date(dateRange.endDate!));

    const data: TChartDataInfo[] = [];
    if (scale === window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY) {
      const currentDate = startOfMonthDate(startDate);
      for (let i = currentDate; i <= endOfMonthDate(endDate); currentDate.setMonth(currentDate.getMonth() + 1)) {
        data.push({ date: new Date(currentDate), value: 0 });
      }
    } else if (scale === window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.DAILY) {
      for (let i = startDate; i <= endDate; startDate.setDate(startDate.getDate() + 1)) {
        data.push({ date: new Date(startDate), value: 0 });
      }
    }

    return data;
  }, [dateRange.endDate, dateRange.startDate, scale]);

  const labels = useMemo(() => {
    const days: string[] = [];
    const step = 2;
    const endIndex = Math.floor((dataInfo.length - 1) / step) * step;
    dataInfo.forEach((data, index) => {
      let date = '';
      if (scale === window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY) {
        if (briefView) {
          if (index === 0 || index === endIndex) {
            date = formatMonth(data.date);
          } else {
            date = formatDate(data.date, 'MMM');
          }
        } else if (index === 0 || data.date.getMonth() === 0) {
          date = formatMonth(data.date);
        } else {
          date = formatDate(data.date, 'MMM');
        }
      } else if (scale === window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.DAILY) {
        date = formatDate(data.date, 'MMM D');
      }
      days.push(date);
    });

    return days;
  }, [briefView, dataInfo, scale]);

  const resultDataSets = useMemo(() => {
    const resultData: any = [];
    if (viewOption) {
      viewOption.dataSets.forEach((dataSetId) => {
        const data = fillData(dataInfo, dataSets, dataSetId, undefined, scale);
        if (data.resultDataSet && data.values.length > 0) {
          resultData.push({
            label:           data.resultDataSet.label,
            data:            data.values,
            borderColor:     data.resultDataSet.color,
            backgroundColor: data.resultDataSet.color,
          });
        }
      });
    } else if (businesses) {
      dataSets.forEach((dataSet, index) => {
        const data = fillData(dataInfo, undefined, undefined, dataSet, scale);
        if (data.resultDataSet && data.values.length > 0) {
          resultData.push({
            label:           getDataSetLabel(dataSet, businesses),
            data:            data.values,
            borderColor:     getDataSetColor(index),
            backgroundColor: getDataSetColor(index),
          });
        }
      });
    }

    return resultData;
  }, [viewOption, dataInfo, dataSets, scale, businesses]);

  const data: any = useMemo(() => {
    return {
      labels,
      datasets: resultDataSets,
    };
  }, [labels, resultDataSets]);

  const showTargets = useRecoilValue(toggleTargetsState);

  const chartOptions = useMemo(() => {
    let unitType: string = window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.COUNT;
    if (dataSets.length > 0) unitType = dataSets[0].unit;
    const baseOptions = briefView ? briefViewOptions(unitType, target) : fullViewOptions(
      unitType,
      onMonthlyLaunch,
      isStackedBar,
    );
    return {
      ...baseOptions,
      plugins: {
        ...baseOptions.plugins,
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const value = context.parsed.y;
              const targetValue = target || 0;

              // Calculate variance percentage
              const variancePercentage = Math.round(targetValue) !== 0
                ? ((value - targetValue) / targetValue) * 100 : 0;

              const formattedValue = unitType === '%'
                ? `${value.toFixed(2)}%`
                : value.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

              const formattedTarget = unitType === '%'
                ? `${targetValue.toFixed(2)}%`
                : targetValue.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

              const displayTarget = showTargets ? `Target: ${formattedTarget}` : '';

              const formattedVariance = Math.abs(variancePercentage).toFixed(2);
              const varianceSymbol = variancePercentage > 0 ? ' above target' : ' below target';
              const variance = showTargets ? `${formattedVariance}% ${varianceSymbol}` : '';

              return [
                `Actual: ${formattedValue}`,
                `${displayTarget}`,
                `${variance}`,
              ];
            },
          },
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor:      'white',
          bodyColor:       'white',
          padding:         10,
          bodySpacing:     4,
          titleSpacing:    10,
          cornerRadius:    4,
          displayColors:   false,
          position:        'nearest',
          intersect:       false,
        },
      },
    };
  }, [briefView, dataSets, onMonthlyLaunch, isStackedBar, target, showTargets]);

  switch (viewOption?.chartType || chartType) {
    case 'bar':
      return (
        <Bar
          data={ data }
          options={ chartOptions }
        />
      );
    case 'line':
      return (
        <Line
          data={ data }
          options={ chartOptions }
        />
      );
    default:
      return null;
  }
};

export default ChartView;
