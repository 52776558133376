import React, { useCallback, useEffect } from 'react';

import { TID } from '@src/types/common';
import { openQBOConnectWindow } from '@src/utils/qbo_connect';

import Button from '@src/components/ui_v2/buttons/button';
import { QBOIcon, RightArrowIcon } from '@src/components/utils/icomoon';

import QuickBooksGuideImg from '@assets/images/qbo-connection-guide.png';

import styles from '../styles.module.scss';

const ConnectLedgerForm = () => {
  const businessId = window.Docyt.currentAdvisor.get(
    'default_accounting_firm',
  )?.id;

  useEffect(() => {
    window.Docyt.vent.on('business:quickbooks:connected', (id: TID) => {
      if (id === businessId) {
        window.Docyt.currentAdvisor.get('default_accounting_firm').qbo_connected = true;
        Backbone.history.navigate('setup_accounting_firm?step=ledger_setup_in_progress', {
          trigger: true,
        });
      }
    });

    return () => {
      window.Docyt.vent.off('business:quickbooks:connected');
    };
  }, [businessId]);

  const handleConnect = useCallback(() => {
    openQBOConnectWindow(businessId);
  }, [businessId]);

  return (
    <div className={ styles['onboarding-form'] }>
      <div className={ styles['onboarding-body'] }>
        <div className="display-flex p-b-20 font-16">
          Docyt needs to connect with your QuickBooks Accountant account to:
        </div>
        <ul className={ styles['qbo-connect-list'] }>
          <li>
            <RightArrowIcon fontSize={ 10 } />
            <p>Instantly audit your clients P&L and Balance Sheets</p>
          </li>
          <li>
            <RightArrowIcon fontSize={ 10 } />
            <p>Ensure accurate and error-free financial reports</p>
          </li>
          <li>
            <RightArrowIcon fontSize={ 10 } />
            <p>Speed up your book closing process</p>
          </li>
        </ul>
        <div className="display-flex justify-content-center m-b-20">
          <Button variant="success" onClick={ handleConnect }>
            <QBOIcon fontSize={ 18 } variant="white" />
            {' '}
            Connect QuickBooks
          </Button>
        </div>
        <div className={ styles['onboarding-guide'] }>
          <p>Please make sure to select the firm</p>
          <img
            alt=""
            src={ QuickBooksGuideImg }
          />
        </div>
      </div>
    </div>

  );
};

export default React.memo(ConnectLedgerForm);
