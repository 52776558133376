import React, { useEffect, useMemo, useRef, useState } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useGetOperationDashboardPreferences }
  from '@src/hooks/queries/operational_dashboard/operation_dashboard_service';
import { IGetDashboardTableServiceResponse } from '@src/requests/operational_dashboard/operation_dashboard_service';
import { TID } from '@src/types/common';
import { ISorting } from '@src/types/sorting';

import TableBody from '@src/components/operational_dashboard/table/table_body';
import TableHead from '@src/components/operational_dashboard/table/table_head';
import { IDashboardData, TDashboardSortColumn,
  TViewOperationalDashboard } from '@src/components/operational_dashboard/types';
import Table from '@src/components/ui_v2/table';
import TableSection from '@src/components/ui_v2/table_section';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import style from './style.module.scss';

interface IDashboardTableProps {
  infiniteQuery: UseInfiniteQueryResult<
    IGetDashboardTableServiceResponse,
    Error
  >;
  records: IDashboardData[];
  view: TViewOperationalDashboard;
  sorting: ISorting<TDashboardSortColumn>;
  viewNotes?: (businessId: TID) => void;
  hasActiveSearch?: boolean;
}

const DashboardTable = ({
  infiniteQuery,
  records,
  sorting,
  view,
  viewNotes,
  hasActiveSearch = false,
}: IDashboardTableProps) => {
  const { data: preferences } = useGetOperationDashboardPreferences(
    { view },
  );

  const { isLoading, error, isFetched } = infiniteQuery;

  // Create a ref for the table to access the header cells
  const tableRef = useRef<HTMLDivElement>(null);
  // State to track the actual number of columns
  const [colCount, setColCount] = useState(6); // Default fallback value

  // Use an effect to count the header cells after render
  useEffect(() => {
    // Function to count table header cells
    const countTableHeaderCells = () => {
      if (tableRef.current) {
        const headerCells = tableRef.current.querySelectorAll('thead tr th');
        if (headerCells.length > 0) {
          setColCount(headerCells.length);
        }
      }
    };

    // Count initially after render
    countTableHeaderCells();

    // Set up a MutationObserver to recount if the DOM changes
    const observer = new MutationObserver(countTableHeaderCells);

    if (tableRef.current) {
      observer.observe(tableRef.current, {
        childList: true,
        subtree: true,
      });
    }

    // Clean up the observer on unmoun
    return () => observer.disconnect();
  }, [preferences]); // Re-run when preferences change as they affect column visibility

  // Only consider it a search with no results if:
  // 1. There's an active search term (passed from parent)
  // 2. Data has been fetched
  // 3. No records were found
  const isSearching = useMemo(() => {
    if (!hasActiveSearch || !infiniteQuery.isFetched) return false;
    return (!records || records.length === 0);
  }, [hasActiveSearch, infiniteQuery.isFetched, records]);

  // Check if there was an API error
  const hasError = Boolean(error && isFetched);

  return (
    <TableSection className={ style['dashboard-table-section'] }>
      <TableSection.Panel>
        <div ref={ tableRef }>
          <Table isRegionScroll sticky query={ infiniteQuery } wrapperClassName={ style['table-container'] }>
            <TableHead preferences={ preferences } sorting={ sorting } view={ view } />
            {isLoading ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={ colCount }>
                    <div className={ style['spinner-container'] }>
                      <SpinnerIcon spin fontSize={ 32 } />
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <TableBody
                preferences={ preferences }
                records={ records }
                viewNotes={ viewNotes }
                isSearching={ isSearching }
                hasError={ hasError }
                colCount={ colCount }
                view={ view }
              />
            )}
          </Table>
        </div>
      </TableSection.Panel>
    </TableSection>
  );
};

export default React.memo(DashboardTable);
