import React, { useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { getDocument } from '@src/requests/documents';
import { invoiceQueueTabsPath } from '@src/routes';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';
import { currencyLocaleFormatter } from '@src/utils/currency';

import DocumentViewPageContent from '@src/components/accounts_payable/documents/document_view_page_content';
import Spinner from '@src/components/ui/spinner';
import Alert from '@src/components/ui_v2/alerts/alert';
import { Button } from '@src/components/ui_v2/buttons';
import FullPageModal from '@src/components/ui_v2/full_page_modal/full_page_modal';
import BannerRegion from '@src/components/ui_v2/layout/banner_region';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import ExpandRegion from '@src/components/ui_v2/layout/expand_region';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import {
  AchIcon,
  BulkSelfPrintCheckIcon,
  BusinessIcon,
  CashIcon,
  CheckCardIcon,
  CreditCardIcon,
  EditAccountIcon,
  EditIcon,
  ForwardOIcon,
  MergeInvoicesIcon,
  PaidIcon,
  PaidPrimaryIcon,
  PayPrimaryIcon,
  PlusIcon,
  StopIcon,
  TrashcanIcon,
} from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import DocumentUploader from './document_uploader';
import { useInvoiceQueueCollection } from './hooks';
import InvoiceQueueTable from './table';
import UploadFile from './upload_file';

import styles from './styles.module.scss';

const TABS = [
  {
    id:    1,
    title: 'All Invoices',
    type:  'queued',
  },
  {
    id:    2,
    title: 'Paid',
    type:  'paid',
  },
  {
    id:    3,
    title: 'Stopped',
    type:  'stopped',
  },
];

const InvoiceQueue = () => {
  const { businessId, queueType } = useParams();
  const collection = useInvoiceQueueCollection({
    businessId: Number(businessId),
    type:       queueType as string,
  });
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState<boolean>(false);
  const [serviceDocumentView, setServiceDocumentView] = useState<IAccountsPayableServiceDocument | null>(null);
  const [documentView, setDocumentView] = useState<IDocument | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadDone, setIsUploadDone] = useState<boolean>(false);
  const [documentUploadCount, setDocumentUploadCount] = useState<number>(0);

  const handleCloseUploader = useCallback(() => {
    setShowAddInvoiceModal(false);
  }, [setShowAddInvoiceModal]);

  const handleClickAddInvoice = useCallback(() => {
    setShowAddInvoiceModal(true);
    setIsUploadDone(false);
  }, []);

  const handleViewInvoiceModal = useCallback(async (invoice: IAccountsPayableServiceDocument) => {
    setIsLoading(true);
    const document = await getDocument(invoice.documentId);
    setIsLoading(false);
    setDocumentView(document);
    setServiceDocumentView(invoice);
    setIsDocumentViewModalOpen(true);
  }, []);

  const handleCloseDocumentViewModal = useCallback(() => {
    setIsDocumentViewModalOpen(false);
    setDocumentView(null);
    setServiceDocumentView(null);
  }, []);

  const handlePrevDocument = useCallback(() => {
    const currentInvoiceIndex = collection.records.findIndex((record) => record.id === serviceDocumentView?.id);
    if (currentInvoiceIndex > 0) {
      const prevInvoice = collection.records[currentInvoiceIndex + 1];
      if (prevInvoice) {
        handleViewInvoiceModal(prevInvoice);
      }
    }
  }, [collection.records, handleViewInvoiceModal, serviceDocumentView?.id]);

  const handleNextDocument = useCallback(() => {
    const currentInvoiceIndex = collection.records.findIndex((record) => record.id === serviceDocumentView?.id);
    if (currentInvoiceIndex > 0 && currentInvoiceIndex < collection.records.length - 1) {
      const nextInvoice = collection.records[currentInvoiceIndex - 1];
      if (nextInvoice) {
        handleViewInvoiceModal(nextInvoice);
      }
    }
  }, [collection.records, handleViewInvoiceModal, serviceDocumentView?.id]);

  const handleReloadClick = useCallback(() => {
    setIsUploadDone(false);
    setShowAddInvoiceModal(false);
    setDocumentUploadCount(0);
    collection.query.refetch();
  }, [collection.query]);

  const onSingleDocumentUpload = useCallback((serviceDocument) => {
    const exists = collection.records.some((record) => record.id === serviceDocument.id);
    if (!exists) {
      collection.records.unshift(serviceDocument);
    }
  }, [collection.records]);

  return (
    <Section.Provider section={ collection.section }>
      { isLoading && <Spinner /> }
      <DetailsRegion className={ styles['detail-region-content-margin-right-0'] }>
        <HeaderRegion>
          <HeaderRegion.Tabs>
            { TABS.map((tab: { id: number, title: string, type: string }) => (
              <HeaderRegion.Tab
                key={ tab.id }
                active={ queueType === tab.type }
                href={ invoiceQueueTabsPath(Number(businessId), tab.type) }
                title={ tab.title }
              />
            )) }
          </HeaderRegion.Tabs>
          <HeaderRegion.BulkActions>
            <HeaderRegion.SelectedItems />
            <HeaderRegion.BulkActionsDropdown
              icon={ <PayPrimaryIcon className="font-22" /> }
              title="Pay"
            >
              <HeaderRegion.BulkAction
                icon={ <AchIcon className="font-22" /> }
                title="Pay with Docyt ACH"
              />
              <HeaderRegion.BulkAction
                icon={ <CheckCardIcon className="font-22" /> }
                title="Pay with Docyt Check"
              />
              <HeaderRegion.BulkAction
                icon={ <BulkSelfPrintCheckIcon className="font-28" /> }
                title="Pay with Self-print Check"
              />
            </HeaderRegion.BulkActionsDropdown>
            <HeaderRegion.BulkActionsDropdown
              icon={ <PaidPrimaryIcon className="font-22" /> }
              title="Mark as Paid"
            >
              <HeaderRegion.BulkAction
                icon={ <CashIcon className="font-22" /> }
                title="Cash"
              />
              <HeaderRegion.BulkAction
                icon={ <CreditCardIcon className="font-22" variant="simple" /> }
                title="Credit Card"
              />
              <HeaderRegion.BulkAction
                icon={ <BusinessIcon className="font-22" /> }
                title="Bank Account (ACH / Debit Card)"
              />
              <HeaderRegion.BulkAction
                icon={ <PaidIcon className="font-22" /> }
                title="Advanced Settlement"
              />
            </HeaderRegion.BulkActionsDropdown>
            <HeaderRegion.BulkActionsDropdown title="More">
              <HeaderRegion.BulkAction
                icon={ <MergeInvoicesIcon className="font-22" /> }
                title="Merge Invoices"
              />
              <HeaderRegion.BulkAction
                icon={ <ForwardOIcon className="font-22" /> }
                title="Forward"
              />
              <HeaderRegion.BulkAction
                icon={ <StopIcon className="font-22" /> }
                title="Do Not Pay"
              />
              <HeaderRegion.BulkAction
                icon={ <EditAccountIcon className="font-22" /> }
                title="Edit Invoices"
              />
              <HeaderRegion.BulkAction
                icon={ <EditIcon className="font-22" /> }
                title="Edit Category"
              />
              <HeaderRegion.BulkAction
                danger
                icon={ <TrashcanIcon className="font-22" /> }
                title="Delete Invoice"
              />
            </HeaderRegion.BulkActionsDropdown>
          </HeaderRegion.BulkActions>
          <HeaderRegion.ActionsArea>
            <Button
              prefixIcon={ <PlusIcon fontSize={ 14 } /> }
              size="compact"
              variant="primary"
              onClick={ handleClickAddInvoice }
            >
              Add invoice
            </Button>
            <ExpandRegion.ToggleButton />
          </HeaderRegion.ActionsArea>
        </HeaderRegion>
        <BannerRegion>
          { documentUploadCount > 0 && (
            <Alert className={ styles['new-data-reload-container'] } variant="success">
              <a
                className={ styles['new-data-link'] }
                role="button"
                tabIndex={ 0 }
                onClick={ handleReloadClick }
              >
                New data available. Click here to reload.
              </a>
            </Alert>
          ) }
        </BannerRegion>
        <TableSection className={ styles['table-section-container'] }>
          { showAddInvoiceModal && !isUploadDone && (
            <DocumentUploader isUploading={ isUploading } onClose={ handleCloseUploader }>
              <UploadFile
                businessId={ Number(businessId) }
                setDocumentUploadCount={ setDocumentUploadCount }
                setIsUploadDone={ setIsUploadDone }
                setIsUploading={ setIsUploading }
                showAddInvoiceModal={ setShowAddInvoiceModal }
                onSingleDocumentUpload={ onSingleDocumentUpload }
              />
            </DocumentUploader>
          ) }
          <InvoiceQueueTable
            businessId={ Number(businessId) }
            collection={ collection }
            onViewInvoiceModal={ handleViewInvoiceModal }
          />
        </TableSection>
        <DetailsRegion.Footer className={ styles['footer-container'] }>
          <DetailsRegion.FooterHint>
            Drag one or more invoices to add in the queue
          </DetailsRegion.FooterHint>
          <DetailsRegion.FooterMetrics
            title="Total Invoices"
            value="210"
          />
          <DetailsRegion.FooterMetrics
            hint={ (
              <>
                Total Amount reflects the sum of all
                <br />
                invoices in the queue, across all states  (In
                <br />
                Verification, Needs Approval, Ready to
                <br />
                Pay, Ready for Credit, etc.). It dynamically
                <br />
                updates to reflect any filters applied or
                <br />
                selected invoices within the queue.
              </>
            ) }
            title="Total Amount"
            value={ currencyLocaleFormatter(collection.totalAmount) }
          />
        </DetailsRegion.Footer>
      </DetailsRegion>
      { isDocumentViewModalOpen && documentView && serviceDocumentView && (
        <FullPageModal>
          <DocumentViewPageContent
            showNavButtons
            document={ documentView }
            serviceDocument={ serviceDocumentView }
            onClose={ handleCloseDocumentViewModal }
            onNextDocument={ handleNextDocument }
            onPrevDocument={ handlePrevDocument }
          />
        </FullPageModal>
      )}
    </Section.Provider>
  );
};

export default React.memo(InvoiceQueue);
