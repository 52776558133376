import React from 'react';

import Table from '@src/components/ui_v2/table';

import { TViewOperationalDashboard } from '../types';
import styles from './style.module.scss';

export type EmptyStateType = 'initial' | 'search' | 'error';

interface IEmptyStateProps {
  colspan: number;
  rowRef?: React.RefObject<HTMLTableRowElement>;
  messageType?: EmptyStateType;
  view: TViewOperationalDashboard;
}

/**
 * Shared empty state component used across table views
 * Shows appropriate messaging based on the contex
 * Customizes message for client vs business views
 */
const EmptyState: React.FC<IEmptyStateProps> = ({
  colspan,
  rowRef,
  messageType = 'initial',
  view,
}) => {
  // Determine if we're showing clients or businesses based on view
  const entityType = view === 'accountant' ? 'clients' : 'businesses';

  // Get message content based on messageType
  const getMessage = () => {
    switch (messageType) {
      case 'search':
        return {
          title: 'No search results found',
          message: `No ${entityType} match your search criteria`,
        };
      case 'error':
        return {
          title: 'Unable to load data',
          message: 'Please check your connection and try again',
        };
      case 'initial':
      default:
        return {
          title: 'No data available here',
          message: `${entityType.charAt(0).toUpperCase() + entityType.slice(1)} will appear once they are added`,
        };
    }
  };

  const { title, message } = getMessage();

  return (
    <Table.Row ref={ rowRef }>
      <Table.Cell colSpan={ colspan } style={ { padding: 0 } }>
        <div className={ styles['empty-state-container'] }>
          <div className={ styles['empty-state-icon-container'] }>
            <svg height="70" viewBox="0 0 100 100" width="70" xmlns="http://www.w3.org/2000/svg">
              <rect fill="#D3D3D3" height="50" width="50" x="25" y="25" />
              <path d="M60 30 L60 70 L30 70 L30 30 Z" fill="#F5F5F5" />
              <path d="M40 40 L70 40" stroke="#D3D3D3" strokeWidth="2" />
              <path d="M40 50 L70 50" stroke="#D3D3D3" strokeWidth="2" />
              <path d="M40 60 L70 60" stroke="#D3D3D3" strokeWidth="2" />
              <circle cx="70" cy="70" fill="#F5F5F5" r="15" stroke="#D3D3D3" strokeWidth="2" />
              <path d="M65 70 L75 70 M70 65 L70 75" stroke="#D3D3D3" strokeWidth="2" />
            </svg>
          </div>
          <h3 className={ styles['empty-state-title'] }>{ title }</h3>
          <p className={ styles['empty-state-message'] }>{ message }</p>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(EmptyState);
