/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useMemo, useCallback, useState } from 'react';

import { useRecoilState } from 'recoil';

import { useUpdateReportDatas, useReportUpdateEstimation } from '@src/hooks/queries/report_service/report_datas';
import { IReport } from '@src/types/report_service/report';
import { IReportData } from '@src/types/report_service/report_data';
import { formatDuration } from '@src/utils/date_helpers';

import { isBudgetColumn, isForecastColumn } from '@src/components/business_reports/custom_reports/hooks';
import Text from '@src/components/ui/text';

import { reportRefreshState, reportRefreshJobId } from '../../atoms';

interface IReportUpdateBannerProps {
  reportDatas: IReportData[],
  report: IReport,
  reportEstimateEnabled?: boolean;
}

const ReportUpdateBanner = ({
  reportDatas,
  report,
  reportEstimateEnabled=false,
}: IReportUpdateBannerProps): JSX.Element => {
  const { mutate } = useUpdateReportDatas();
  const [update, setUpdate] = useRecoilState(reportRefreshState);
  const [jobId, setJobId] = useRecoilState(reportRefreshJobId);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const hasBudgetOrForecastColumns = useMemo(() => {
    return report.ptdColumns.some((column) => isBudgetColumn(column) || isForecastColumn(column));
  }, [report.ptdColumns]);

  useEffect(() => {
    const updatingDatas = reportDatas.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_QUEUED
      || reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_STARTED
    ));
    setUpdate(updatingDatas.length !== 0);
  }, [reportDatas, setUpdate]);

  const { isFailed, errMsg, isValidationFailed, validationError, isBudgetForecastUpdated } = useMemo(() => {
    const failedData = reportDatas.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_FAILED
      || (reportData.errorMsg && reportData.errorMsg !== '')
    ));
    const failed = failedData.length !== 0;

    const validationFailedDatas = reportDatas.filter((reportData) => (
      reportData.validationResult && reportData.validationResult !== '' && reportData.validationResult !== 'OK'
    ));

    const budgetForecastUpdated = reportDatas.some((reportData) => reportData.budgetForecastUpdated === true);

    return {
      isFailed:                failed,
      errMsg:                  failedData[0]?.errorMsg || '',
      isValidationFailed:      validationFailedDatas.length > 0,
      validationError:         validationFailedDatas[0]?.validationResult || '',
      isBudgetForecastUpdated: budgetForecastUpdated,
    };
  }, [reportDatas]);

  const handleRefillReportDatas = useCallback(() => {
    if (isRefreshing || update) return;
    const reportData = reportDatas[0];
    if (!reportData?.reportId) return;

    setIsRefreshing(true);
    setUpdate(true);
    mutate({
      reportId:       reportData.reportId,
      startDate:      reportData.startDate,
      endDate:        reportDatas[reportDatas.length - 1].endDate,
      periodType:     reportData.periodType,
      refillReportDataOnly: true,
    }, {
      onSuccess: (data) => {
        if (reportEstimateEnabled) {
          setJobId(data?.jobId || '');
        }
        setIsRefreshing(false);
        setUpdate(true);
      },
      onError: () => {
        setIsRefreshing(false);
      },
    });
  }, [isRefreshing, update, reportDatas, setUpdate, mutate, reportEstimateEnabled, setJobId]);

  const handleErrorLinkClick = useCallback((e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      if ((target as HTMLAnchorElement).getAttribute('href') === '#refill_report_data_only') {
        e.preventDefault();
        e.stopPropagation();
        handleRefillReportDatas();  
      }
    }
  }, [handleRefillReportDatas]);

  const estimation = useReportUpdateEstimation(
    reportDatas[0]?.reportId || '',
    jobId || '',
    update && Boolean(jobId) && reportEstimateEnabled,
  );

  const renderEstimationText = () => {
    if (!jobId || estimation.isLoading || !estimation.data) {
      return window.Docyt.Common.Constants.Messages.CUSTOM_REPORT_UPDATING_STATE;
    }

    const { predictRemainTimeInSecs } = estimation.data;
    if (update && predictRemainTimeInSecs > 0) {
      return `${window.Docyt.Common.Constants.Messages.CUSTOM_REPORT_UPDATING_STATE}  (Estimated time remaining: ${formatDuration(predictRemainTimeInSecs)})`;
    }
    return 'This report was generated';
  };

  if (update) {
    return (
      <div className="report-status-panel m-t-20">
        <Text as="label" className="m-l-10 m-b-0" fontSize={ 14 }>
          { renderEstimationText() }
        </Text>
      </div>
    );
  }

  if (isFailed) {
    // TODO: We should manage it with error status. Need backend efforts for this.
    if (errMsg === window.Docyt.Common.Constants.Messages.REPORT_CHANGES_DETECTED) {
      return (
        <div className="report-status-panel m-t-20">
          <Text as="label" className="m-l-10 m-b-0">{errMsg}</Text>
        </div>
      );
    }
    return (
      <div className="report-status-panel warning m-t-20">
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {/* eslint-disable-next-line react/no-danger */}
          <Text
            as="label"
            className="m-b-0"
            dangerouslySetInnerHTML={ { __html: errMsg } }
            onClick={ handleErrorLinkClick }
          />
        </Text>
      </div>
    );
  }

  if (isValidationFailed) {
    return (
      <div className="report-status-panel warning m-t-20">
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {/* eslint-disable-next-line react/no-danger */}
          <Text as="label" className="m-b-0" dangerouslySetInnerHTML={ { __html: validationError } } />
        </Text>
      </div>
    );
  }

  if (hasBudgetOrForecastColumns && isBudgetForecastUpdated) {
    return (
      <div className="report-status-panel m-t-20">
        <Text as="label" className="m-l-10 m-b-0">Changes detected in Budget/Forecast Report. Please refresh to see updated data.</Text>
      </div>
    );
  }

  return (
    <div className="report-status-panel m-t-20">
      <Text as="label" className="m-l-10 m-b-0">This report was generated</Text>
    </div>
  );
};

export default ReportUpdateBanner;
