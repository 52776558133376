import React, { useCallback, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useGetPortfolioWidgets } from '@src/hooks/queries/portfolio_services/widgets';
import { IBusinessSimple } from '@src/types/businesses';
import { TDateRange, TID } from '@src/types/common';
import { IPortfolioDashboard } from '@src/types/portfolio';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import { togglePortfolioViewState } from '@src/components/portfolio_dashboard/atoms';
import Spinner from '@src/components/ui/spinner';
import { DateRangeInput } from '@src/components/ui_v2/inputs';

import KPIChartView from './kpi_chart_view';
import Table from '../tabular_view';
import { getDefaultDateRange } from '../charts/chart_helpers';
import { TChartScale } from '../charts/types';

import styles from './styles.module.scss';

interface IAllKPIsViewProps {
  portfolioId: TID,
  dashboard: IPortfolioDashboard,
  businesses: IBusinessSimple[],
}

const AllKPIsView = ({
  portfolioId,
  dashboard,
  businesses,
}: IAllKPIsViewProps) => {
  const defaultRange = getDefaultDateRange(window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY as TChartScale);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: formatDate(defaultRange.from, API_DATE_FORMAT),
    endDate:   formatDate(defaultRange.to, API_DATE_FORMAT),
  });
  const [chartViewDateRange, setChartViewDateRange] = useState<TDateRange>(dateRange);

  const query = useGetPortfolioWidgets({
    dashboardId: dashboard.id,
    from:        chartViewDateRange.startDate,
    to:          chartViewDateRange.endDate,
  }, () => {
    setIsLoading(false);
  });
  const widgets = useMemo(() => {
    return query.data?.collection || [];
  }, [query.data?.collection]);

  const handleDateRange = useCallback((range: TDateRange) => {
    setDateRange(range);
    if (!!range.startDate && !!range.endDate) {
      setIsLoading(true);
      setChartViewDateRange(range);
    }
  }, [setDateRange, setChartViewDateRange]);

  const isChartView = useRecoilValue(togglePortfolioViewState);

  const renderTableView = () => (
    <Table businesses={ businesses } collection={ widgets } />
  );

  const renderChartView = () => (
    <div>
      <div className={ styles.range }>
        <DateRangeInput
          value={ dateRange }
          onChange={ handleDateRange }
        />
      </div>
      { isLoading && <Spinner /> }
      {
        widgets.map((widget) => (
          <KPIChartView
            key={ widget.id }
            className="col-sm-6 col-md-4 col-lg-4 pointer"
            dashboard={ dashboard }
            dateRange={ chartViewDateRange }
            portfolioId={ portfolioId }
            widget={ widget }
          />
        ))
      }
    </div>
  );

  return isChartView ? renderChartView() : renderTableView();
};

export default AllKPIsView;
