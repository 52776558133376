import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useRecoilState } from 'recoil';

import toastr from '@lib/toastr';

import { firstMessageAtom } from '@src/components/ai_chatbot/atoms';
import ChatInput from '@src/components/ai_chatbot/components/chat_input';
import ChatMessageItem from '@src/components/ai_chatbot/components/chat_message_item';
import {
  chatChannelCreationMessage,
  FAQ,
  welcomeMessage,
} from '@src/components/ai_chatbot/constants';
import useChatProvider from '@src/components/ai_chatbot/hooks/use_chat_provider';
import useSendbirdHelper from '@src/components/ai_chatbot/hooks/use_sendbird_helper';
import { ChatMessageStatus } from '@src/components/ai_chatbot/types';
import { Button } from '@src/components/ui_v2/buttons';
import { DocytBotAi } from '@src/components/utils/icomoon';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const WelcomeView = () => {
  const defaultQuestion = window.localStorage.getItem('default_question') || '';
  const { sdk } = useSendbirdHelper();
  const { startNewThread } = useChatProvider();
  const [firstMessage, setFirstMessage] = useRecoilState(firstMessageAtom);
  const [loader, setLoader] = useState<boolean>(false);
  const buttonClass = classNames(styles['chat-btn'], 'chat-thread-btn');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMessageSend = useCallback(
    async (message: string) => {
      const messageSignature = crypto.randomUUID();
      setLoader(true);
      try {
        // Create a new channel with user + bot
        await startNewThread(message, messageSignature);
      } catch {
        toastr.error(
          chatChannelCreationMessage.errorSendingMessage.message,
          chatChannelCreationMessage.errorSendingMessage.title,
        );
      }
    },
    [startNewThread, setLoader],
  );

  const handleFAQClick = useCallback(
    (message: string) => () => {
      handleMessageSend(message);
    },
    [handleMessageSend],
  );

  useEffect(() => {
    // clear it when activeThreadId is set
    return () => {
      setFirstMessage({ message: '', messageSignature: '' });
    };
  }, [setFirstMessage]);

  useEffect(() => {
    if (isEmpty(sdk)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [sdk, setIsLoading]);

  useEffect(() => {
    if (!isEmpty(sdk) && defaultQuestion !== '') {
      handleMessageSend(defaultQuestion);
      window.localStorage.removeItem('default_question');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, defaultQuestion]);

  if (isLoading) {
    return (
      <div className={ styles['chat-top-section'] }>
        <div className={ styles['chat-no-message'] }>
          <div className={ styles['no-message-content'] }>
            <div className={ styles['text-container'] }>
              <div className={ styles.spinner } />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={ styles['chat-wrapper'] }>
      <div className={ styles['chat-top-section'] }>
        {!firstMessage.message ? (
          <div className={ styles['chat-no-message'] }>
            <div className={ styles['no-message-content'] }>
              <div className={ styles['text-container'] }>
                <DocytBotAi fontSize={ 25 } />
                <div className={ styles['text-content'] }>
                  <p className={ styles['title-text'] }>
                    <span className={ styles['title-bold'] }>{welcomeMessage.title}</span>
                    <span className={ styles['title-light'] }>{welcomeMessage.second_title}</span>
                  </p>
                  <p className={ styles['message-text'] }>{welcomeMessage.message}</p>
                </div>
              </div>
            </div>
            <div className={ styles['quick-chat-buttons'] }>
              {FAQ.map((item) => (
                <Button
                  key={ item.message }
                  className={ buttonClass }
                  variant="link"
                  onClick={ handleFAQClick(item.message) }
                >
                  { item.summary }
                </Button>
              )) }
            </div>
          </div>
        ) : (
          <div className={ styles['chat-message-scroll-outer'] }>
            <ChatMessageItem
              key={ firstMessage.messageSignature }
              answer=""
              channelAnswerMessageId={ 0 }
              channelQuestionMessageId={ 0 }
              chatMessageStatus={ ChatMessageStatus.QuestionSentToSendbird }
              id=""
              messageSignature={ firstMessage.messageSignature }
              question={ firstMessage.message }
            />
          </div>
        ) }
      </div>
      <div className={ styles['chat-bottom-section'] }>
        <ChatInput disabled={ loader } handleMessageSend={ handleMessageSend } />
      </div>
    </div>
  );
};

export default React.memo(WelcomeView);
